<template>
  <swiper
    ref="mySwiper1"
    class="swiper-centered-slides-importer-2 p-1"
    :options="swiperOptions"
    @select-swiper-index="selectedIndex"
  >
    <swiper-slide
      v-for="(ipaCard, index) in ipainformation"
      :key="index"
    >
      <div
        class="swiper-text"
        style="margin-bottom: 10px"
      >
        {{ ipaCard.month_name }} {{ Year }}
      </div>
      <dropzone-card-2
        :ipa-card="ipaCard"
        :year="Year"
        :handler="CollectFilesAndSend"
      />
    </swiper-slide>

    <!-- Add Arrows -->
    <div
      slot="button-next"
      ref="btnswiperNext"
      class="swiper-button-next"
      style="display: none;"
    />
    <div
      slot="button-prev"
      ref="btnswiperPrev"
      class="swiper-button-prev"
      style="display: none;"
    />
  </swiper>
</template>

<script>
import {
  Swiper, SwiperSlide,
} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Swal from 'sweetalert2'
import listsService from '@core/services/lists/listsService'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DropzoneCard2 from '../dropzone/DropzoneCard2.vue'

export default {
  components: {
    Swiper,
    SwiperSlide,
    DropzoneCard2,
  },
  props: {
    ipainformation: {
      type: Array,
      required: true,
    },
    refreshIpaData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      Year: '',
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 30,
        centeredSlides: true,
        slideToClickedSlide: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        allowTouchMove: false,
      },
    }
  },
  created() {
    this.$parent.$on('next', this.next)
    this.$parent.$on('prev', this.prev)
    this.$parent.$on('select-swiper-index', this.selectedIndex)
  },
  mounted() {
    this.initialState()
  },
  methods: {
    initialState() {
      const Month = parseInt(moment().format('MM'), 10)
      const Year = moment().format('YYYY')
      this.selectedIndex(Month, Year)
    },
    next() {
      if (this.$refs.btnswiperNext) this.$refs.btnswiperNext.click()
    },
    prev() {
      if (this.$refs.btnswiperPrev) this.$refs.btnswiperPrev.click()
    },
    selectedIndex(month, year) {
      const index = month - 1
      this.Year = year
      this.$refs.mySwiper1.$swiper.slideTo(index, 0)
    },
    /* eslint-disable no-param-reassign */
    CollectFilesAndSend(filesArray, ipacard) {
      console.log(ipacard)
      return new Promise(resolve => {
        const datefileuploaded = moment(`${this.Year}-${ipacard.month_name}-01`).format('YYYY-MM-DD')
        const files = Array.from(filesArray)
        let FileNames = ''
        const formData = new FormData()
        files.forEach(file => {
          FileNames += `${file.name}, `
          formData.append('files', file)
        })
        formData.append('dateInFile', datefileuploaded)
        formData.append('idIpa', ipacard.idIpa)
        Swal.fire({
          title: 'Upload File',
          text: `Do you want to insert the next File ${FileNames}`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes, Analyze the file!',
        }).then(result => {
          if (result.isConfirmed) {
            this.swiperModClass = ''
            try {
              listsService.uploadFile(formData).then(response => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Succes',
                    text: `${response.data.status}`,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                })
                this.refreshIpaData()
                resolve()
              }).catch(error => {
                if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Logged In Error',
                      text: error.response.data.message,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Network Error',
                      text: 'Your network connection is not available',
                      icon: 'WifiOffIcon',
                      variant: 'danger',
                    },
                  })
                } else {
                // Something happened in setting up the request that triggered an Error
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Somethig went wrong',
                      text: 'Try Again later',
                      icon: 'WifiOffIcon',
                      variant: 'danger',
                    },
                  })
                }
                resolve()
              })
            } catch (error) {
              resolve()
            }
            FileNames = ''
          } else {
            FileNames = ''
            resolve()
          }
        })
      })
    },
    /* eslint-enable no-param-reassign */
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
</style>
