<template>
  <div
    class="rounded swiper-shadow swiper-mod"
    :class="swiperModClass"
    style="padding-top: 10px; padding-bottom: 20px; width: 100%;"
    @click="onClickFile"
    @dragenter="onDragOver_Enter"
    @dragleave="onDragLeave_End"
    @dragend="onDragLeave_End"
    @dragover="onDragOver_Enter"
    @drop="onDrop"
  >
    <b-button
      v-if="loading"
      variant="primary"
      disabled
      style="width: 290px; margin-top: -12px; margin-bottom: -22px; height: 125px; background-color: transparent !important; color: #262262 !important; border: none;"
    >
      <b-spinner small />
      Loading...
    </b-button>
    <div v-if="!loading">
      <feather-icon
        :icon="ipaCard.fileNum > 0 ? 'CheckIcon' : 'UploadCloudIcon'"
        size="20"
      />
      <div
        class="swiper-text pt-md-1 pt-sm-50"
        style="padding-top: 5px; padding-bottom: 10px "
      >
        {{ ipaCard.fileNum > 0 ? ipaCard.fileNum : 'UpLoad ...' }}
      </div>
    </div>
    <div
      v-if="!loading"
      class="row inforoaster"
      style="padding: 20px; padding-top: 0px; padding-bottom: 0px;"
    >
      <div
        class="col-3"
        style="display: grid;"
      >
        <small style="font-size: 0.650rem;">New</small>
        <small style="font-size: 0.650rem; font-weight: bold">{{ ipaCard.new }}</small>
      </div>
      <div
        class="col-3"
        style="display: grid;"
      >
        <small style="font-size: 0.650rem;">Pending</small>
        <small style="font-size: 0.650rem; font-weight: bold">{{ ipaCard.pending }}</small>
      </div>
      <div
        class="col-3"
        style="display: grid;"
      >
        <small style="font-size: 0.650rem;">Duplicated</small>
        <small style="font-size: 0.650rem; font-weight: bold">{{ ipaCard.duplicated }}</small>
      </div>
      <div
        class="col-3"
        style="display: grid;"
      >
        <small style="font-size: 0.650rem;">Terminated</small>
        <small style="font-size: 0.650rem; font-weight: bold">{{ ipaCard.terminated }}</small>
      </div>
    </div>

  </div>
</template>

<script>
import { BSpinner, BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BSpinner,
  },
  props: {
    ipaCard: {
      type: Object,
      required: true,
    },
    year: {
      type: String,
      required: true,
    },
    handler: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      swiperModClass: '',
      loading: false,
    }
  },
  methods: {
    onClickFile() {
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('multiple', '')
      input.onchange = () => {
        this.loading = true
        this.handler(input.files, this.ipaCard).then(() => {
          this.loading = false
        })
      }
      input.click()
      this.swiperModClass = ''
    },
    onDrop(e) {
      this.loading = true
      this.handler(e.dataTransfer.files, this.ipaCard).then(() => {
        this.loading = false
      })
      e.preventDefault()
      e.stopPropagation()
    },
    onDragOver_Enter(e) {
      this.swiperModClass = 'mydesign'
      e.preventDefault()
      e.stopPropagation()
    },
    onDragLeave_End(e) {
      this.swiperModClass = ''
      e.preventDefault()
      e.stopPropagation()
    },
    loaderState() {
      this.loading = !this.loading
    },
  },
}
</script>
